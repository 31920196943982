import React from 'react'

interface PageProps {
  children: React.ReactElement
}

const Page = ({ children }: PageProps) => {
  return children
}

export default Page
