import App from 'next/app'
import { withRouter, NextRouter } from 'next/router'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/nextjs'
import { initAmplitude, sendAmplitudeEvent, AmplitudeEventTypes } from 'services/amplitude'
import { GlobalStyle } from '../styles/GlobalStyles'
import theme from '../styles/theme'
import Page from '../components/Page'
import { AmplitudeExperimentProvider } from 'components/AmplitudeExperiments/AmplitudeExperimentProvider'

interface AppProps {
  Component: React.ReactElement
  pageProps: Record<string, unknown>
  router: NextRouter
}

type RouteChangeCompleteHandler = (url: string, extra: { shallow: boolean }) => void

const onRouteChangeComplete: RouteChangeCompleteHandler = (_url, { shallow }) => {
  const { hash, search, pathname } = window.location

  sendAmplitudeEvent(AmplitudeEventTypes.PageViewed, {
    hash,
    query: search,
    pathname,
    shallow,
  })
}

const onPageLoad = () => {
  const { hash, search, pathname } = window.location

  sendAmplitudeEvent(AmplitudeEventTypes.PageViewed, {
    hash,
    query: search,
    pathname,
    shallow: false,
  })
}

export class MyApp extends App<AppProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtra('componentStack', errorInfo.componentStack)
      Sentry.captureException(error)
    })
  }

  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    initAmplitude()

    this.props.router.events.on('routeChangeComplete', onRouteChangeComplete)
    window.addEventListener('load', onPageLoad)
  }

  componentWillUnmount() {
    this.props.router.events.off('routeChangeComplete', onRouteChangeComplete)
    window.removeEventListener('load', onPageLoad)
  }

  render() {
    const { Component, pageProps } = this.props
    return (
      <AmplitudeExperimentProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Page {...pageProps}>
            <Component {...pageProps} />
          </Page>
        </ThemeProvider>
      </AmplitudeExperimentProvider>
    )
  }
}

export default withRouter(MyApp)
