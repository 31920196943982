// ExperimentContext.ts
import { createContext } from 'react'
import { ExperimentClient } from '@amplitude/experiment-js-client'

export interface AmplitudeExperimentContextValue {
  experiment?: ExperimentClient
  isVariantActive: (variantId: string, variantValue?: string) => boolean | string | undefined
  /**
   * @description Returns true if the user is in the experiment with variant `control`.
   */
  isControlActive: (variantId: string, defaultControl?: string) => boolean | string | undefined
  getPayloadVariant: <T>(variantId: string) => T | null
  /**
   * Tracks an exposure event for the current variant of the given flag key
   * @see https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#exposure
   * @param key - The flag key to identify the flag or experiment variant to track an exposure event for.
   */
  trackExposureEvent: (key: string) => void
}

export const AmplitudeExperimentContext = createContext<
  AmplitudeExperimentContextValue | undefined
>(undefined)
