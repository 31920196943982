import React, { useState } from 'react'
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client'
import amplitude from 'amplitude-js'

import {
  AmplitudeExperimentContext,
  AmplitudeExperimentContextValue,
} from 'components/AmplitudeExperiments/AmplitudeExperimentContext'

const AmplitudeExperimentProvider: React.FC<Record<string, unknown>> = ({ children }) => {
  const [experiment, setExperiment] = useState<ExperimentClient | undefined>(undefined)

  const startExperiment = async (experiment: ExperimentClient) => {
    const exp = await experiment.fetch()
    setExperiment(exp)
  }

  React.useEffect(() => {
    if (
      process.env.AMPLITUDE_API_KEY &&
      process.env.AMPLITUDE_API_KEY !== 'DEBUG' &&
      process.env.AMPLITUDE_EXP_API_KEY &&
      process.env.AMPLITUDE_EXP_API_KEY !== 'DEBUG'
    ) {
      amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY)
      /**
       * `initializeWithAmplitudeAnalytics` automatically initializes user & exposure providers
       * @see https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#integrations
       */
      const exp = Experiment.initializeWithAmplitudeAnalytics(process.env.AMPLITUDE_EXP_API_KEY)
      startExperiment(exp)
    }
  }, [])

  const isVariantActive = (variantId: string, variantValue?: string) => {
    if (!experiment) {
      return false
    }
    const variantObject = experiment.variant(variantId)
    if (variantValue) {
      return variantObject.value === variantValue
    }

    return variantObject.value && variantObject.value !== 'control'
  }

  const isControlActive = (variantId: string, defaultControl = 'control') =>
    isVariantActive(variantId, defaultControl)

  function getPayloadVariant<T>(variantId: string): T | null {
    if (!experiment) {
      return null
    }
    const variantObject = experiment.variant(variantId)

    return variantObject.payload as T
  }

  const trackExposureEvent = (key: string) => {
    if (!experiment) {
      return
    }
    experiment.exposure(key)
  }

  const value: AmplitudeExperimentContextValue = {
    experiment,
    isVariantActive,
    isControlActive,
    getPayloadVariant,
    trackExposureEvent,
  }

  return (
    <AmplitudeExperimentContext.Provider value={value}>
      {children}
    </AmplitudeExperimentContext.Provider>
  )
}

export { AmplitudeExperimentContext, AmplitudeExperimentProvider }
