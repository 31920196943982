import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MaisonNeue-Medium';
    src: url("/static/maison-neue--medium.woff2") format("woff2"),
         url("/static/maison-neue--medium.woff") format("woff"),
         url("/static/maison-neue--medium.otf") format("opentype");
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 200;
    src: url("/static/GreycliffCF-Light.otf") format("opentype");
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: normal;
    font-weight: 400;
    src: url("/static/GreycliffCF-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: bold;
    font-weight: 700;
    src: url("/static/GreycliffCF-DemiBold.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: 100;
    src: url("/static/QuincyCF-Thin.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: 200;
    src: url("/static/QuincyCF-Light.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: 400;
    src: url("/static/QuincyCF-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: bold;
    font-weight: 700;
    font-style: italic;
    src: url("/static/QuincyCF-Bold-Italic.otf") format("opentype");
  }

  body {
    background: ${props => props.theme.colors.white};
  }

  a {
    cursor: pointer;
  }

  * {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
    }
  }
  button {
    font-family: 'Greycliff CF';
    border: none;
    outline: none;
    background: none;
    &:focus {
      outline: none;
    }
  }

  h1 {
    color: ${props => props.theme.colors.green};
    font-weight: normal;
    font-family: 'Greycliff CF';
  }

  body {
    margin: 0;
    font-family: 'Greycliff CF', 'MaisonNeue-Medium', 'Helvetica', 'sans-serif';
    font-size: 16px;
    line-height: 1.4rem;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  .position-relative {
    position: relative;
  }

  .react-dropdown-select-input,
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
`
