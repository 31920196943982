import amplitude from 'amplitude-js'

import { APPLICATION_NAME } from 'app-constants'

export const initAmplitude = (): void => {
  if (process.env.AMPLITUDE_API_KEY && process.env.AMPLITUDE_API_KEY !== 'DEBUG') {
    try {
      amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY)
    } catch (error) {
      //Silent fail as can't log. Just don't want amplitude errors to impact the UI
    }
  }
}

export const setAmplitudeUserId = (userId: string): void => {
  if (
    process.env.AMPLITUDE_API_KEY &&
    process.env.AMPLITUDE_API_KEY !== 'DEBUG' &&
    process.browser
  ) {
    try {
      amplitude.getInstance().setUserId(userId)
    } catch (error) {
      //Silent fail as can't log. Just don't want amplitude errors to impact the UI
    }
  }
}

export type AmplitudeProperties = Record<string, string | number | undefined | boolean>

export const sendAmplitudeEvent = (
  eventType: string,
  eventProperties?: AmplitudeProperties,
): void => {
  if (
    process.env.AMPLITUDE_API_KEY &&
    process.env.AMPLITUDE_API_KEY !== 'DEBUG' &&
    process.browser
  ) {
    const defaultEventProperties: AmplitudeProperties = {
      application: APPLICATION_NAME,
    }
    try {
      amplitude.getInstance().logEvent(eventType, { ...defaultEventProperties, ...eventProperties })
    } catch (error) {
      //Silent fail as can't log. Just don't want amplitude errors to impact the UI
    }
  }
}

export enum AmplitudeEventTypes {
  PageViewed = 'Page Viewed',
  RedirectToPrePurchase = 'Redirect To Pre Purchase',
  PublicFAQClicked = 'Public FAQ Clicked',
}

export enum AmplitudeExperimentKeys {}
