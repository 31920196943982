const SPACING_FACTOR = 8

/**
 * Target size for each breakpoint (reference: https://www.npmjs.com/package/styled-components-breakpoint#default-breakpoints)
 * mobile	0px (0em)	        Targeting all devices
 * tablet	737px (46.0625em)	Targeting devices that are LARGER than the iPhone 6 Plus (which is 736px in landscape mode)
 * desktop	1195px (74.6875em)	Targeting devices that are LARGER than the 11" iPad Pro (which is 1194px in landscape mode)
 */

export const breakpoints = {
  zero: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  design: 1440,
  broader: 1536,
  mobile: 737,
}

const theme = {
  colors: {
    lightBlue: '#F5F9FF',
    lightBlue2: '#e0d3f5',
    midBlue: '#E1E9F6',
    blue: '#4D7EC3',
    blue2: '#610BEF',
    blue3: '#6424cc',
    skyBlue: '#259EEC',
    darkBlue: '#3B66A3',
    darkBlue2: '#4E4B66',
    darkBlue3: '#587DBE',
    secondaryBlue: '#005BD4',
    green: '#27A46A',
    green2: '#3CBC73',
    green3: '#51A16F',
    lightGreen: '#D4EDD9',
    lightGreen2: '#F0EEEA',
    steelBlue: '#587ebe',
    lightTeal: '#BDE1D4',
    red: '#EB6157',
    red2: '#DA695D',
    urgent: '#CA024F',
    rose: '#F8D7D9',
    maroon: '#90464B',
    yellow: '#FBD979',
    paleYellow: '#FFE5A4',
    orange: '#F2994A',
    softCream: '#F4F2EE',
    cream: '#F2EEE2',
    sky: '#F5F9FF',
    white: '#FFFFFF',
    pink: '#F3D5DD',
    lightGray: '#FAFAFA',
    lightGray1: '#F7F7F7',
    lightGray2: '#dddbdb',
    blueGray: '#7F8895',
    blueGray2: '#A0A3BD',
    gray: '#D7D7D7',
    gray1: '#777C7F',
    gray2: '#1a191e',
    gray3: '#808080',
    arrorBackgroundGray: '#EFF0F7',
    backgroundGray: '#FCFCFC',
    backgroundGrayDark: '#EFF0F6',
    warmGray: '#7A7575',
    warmGray1: '#4C4948',
    lighterGray: '#BEBEBE',
    lightBlueGray: '#d9dbe9',
    lightBlueGray2: '#F7F7FC',
    lightBlueGray3: '#eff0f8',
    tealGray: '#6E7191',
    greyGreen: '#455B55',
    darkGreen: '#1c3f28',
    darkGreen2: '#14142B',
    mediumGray: '#999999',
    darkGray: '#505050',
    offBlack: '#505050',
    offBlack2: '#14142b',
    offBlack3: '#10100e',
    black: '#000000',
    linkBlue: '#45659e',
    purple: '#7643ff',
    lightPurple: '#9067fe',
    lightPurple1: '#BFBEFC',
    purple2: '#301F4C',
    darkBlue4: '#0A3355',
    darkBlue5: '#005fad',
  },
  breakpoints,
  spacing: (spacing: number): number => SPACING_FACTOR * spacing,
}

export type Theme = typeof theme

export default theme
